import { default as bookedY3ufEPnFcLMeta } from "/vercel/path0/pages/auth/booked.vue?macro=true";
import { default as calendarQEnu3Hze2kMeta } from "/vercel/path0/pages/auth/calendar.vue?macro=true";
import { default as confirmTAqFl1RFWNMeta } from "/vercel/path0/pages/auth/confirm.vue?macro=true";
import { default as connect_45email9C0hdtbGizMeta } from "/vercel/path0/pages/auth/connect-email.vue?macro=true";
import { default as indexNwoJG7pLnGMeta } from "/vercel/path0/pages/auth/forgot-password/index.vue?macro=true";
import { default as resetumQrL5DxQlMeta } from "/vercel/path0/pages/auth/forgot-password/reset.vue?macro=true";
import { default as onboardingMzot0Eex8XMeta } from "/vercel/path0/pages/auth/onboarding.vue?macro=true";
import { default as signinBiAH3O7TaSMeta } from "/vercel/path0/pages/auth/signin.vue?macro=true";
import { default as signupVML5S8aMRqMeta } from "/vercel/path0/pages/auth/signup.vue?macro=true";
import { default as verify_45emailx7i9kZK0fLMeta } from "/vercel/path0/pages/auth/verify-email.vue?macro=true";
import { default as reportsqDuWJBqA2EMeta } from "/vercel/path0/pages/dashboard/activity/reports.vue?macro=true";
import { default as _91id_936SfrEcutoyMeta } from "/vercel/path0/pages/dashboard/customer/contacts/[id].vue?macro=true";
import { default as indexSWwdUv6cm6Meta } from "/vercel/path0/pages/dashboard/customer/contacts/index.vue?macro=true";
import { default as inboxl0NAbn6a9LMeta } from "/vercel/path0/pages/dashboard/inbox.vue?macro=true";
import { default as _91id_93UpKkRtZj0PMeta } from "/vercel/path0/pages/dashboard/pipeline/leads/[id].vue?macro=true";
import { default as indexpJSxYR0FjhMeta } from "/vercel/path0/pages/dashboard/pipeline/leads/index.vue?macro=true";
import { default as indexV157enCUYtMeta } from "/vercel/path0/pages/dashboard/pipeline/opportunities/index.vue?macro=true";
import { default as _91id_93vnM7dqMksVMeta } from "/vercel/path0/pages/dashboard/resources/b2b-database/[id].vue?macro=true";
import { default as indexT2Cr9lA5HCMeta } from "/vercel/path0/pages/dashboard/resources/b2b-database/index.vue?macro=true";
import { default as _91id_93F3qHfwUg2uMeta } from "/vercel/path0/pages/dashboard/resources/idb2b-resources/[id].vue?macro=true";
import { default as addAqZ8mmgflfMeta } from "/vercel/path0/pages/dashboard/resources/idb2b-resources/add.vue?macro=true";
import { default as indexGCpDFWn0rOMeta } from "/vercel/path0/pages/dashboard/resources/idb2b-resources/index.vue?macro=true";
import { default as _91id_93wknaxNmt6ZMeta } from "/vercel/path0/pages/dashboard/resources/idb2b-resources/my-resources/[id].vue?macro=true";
import { default as addyN6luBLCETMeta } from "/vercel/path0/pages/dashboard/resources/idb2b-resources/my-resources/add.vue?macro=true";
import { default as indexj6b4GAWwsyMeta } from "/vercel/path0/pages/dashboard/resources/lookalike-search/index.vue?macro=true";
import { default as resultJ5CK5jas1HMeta } from "/vercel/path0/pages/dashboard/resources/lookalike-search/result.vue?macro=true";
import { default as _91name_93x5yNNmtBhUMeta } from "/vercel/path0/pages/dashboard/settings/connect/integration/[name].vue?macro=true";
import { default as assembly_45aihAR68eXIh2Meta } from "/vercel/path0/pages/dashboard/settings/connect/integration/assembly-ai.vue?macro=true";
import { default as better_45contactuWV7GmtirVMeta } from "/vercel/path0/pages/dashboard/settings/connect/integration/better-contact.vue?macro=true";
import { default as indexCkqlD9VIjsMeta } from "/vercel/path0/pages/dashboard/settings/connect/integration/index.vue?macro=true";
import { default as smartlead_45aiFaTRFc0FQ3Meta } from "/vercel/path0/pages/dashboard/settings/connect/integration/smartlead-ai.vue?macro=true";
import { default as integrationrbPBxl8g9LMeta } from "/vercel/path0/pages/dashboard/settings/connect/integration.vue?macro=true";
import { default as twilio0oyjc2DA3HMeta } from "/vercel/path0/pages/dashboard/settings/connect/twilio.vue?macro=true";
import { default as indexsYg5vFPOgKMeta } from "/vercel/path0/pages/dashboard/settings/index.vue?macro=true";
import { default as generalf3X3XtthRpMeta } from "/vercel/path0/pages/dashboard/settings/organization/general.vue?macro=true";
import { default as ideal_45customer_45profilenMxiI41AvVMeta } from "/vercel/path0/pages/dashboard/settings/organization/ideal-customer-profile.vue?macro=true";
import { default as lead_45settingsR8i2qd3362Meta } from "/vercel/path0/pages/dashboard/settings/organization/lead-settings.vue?macro=true";
import { default as manage_45tokens87iPvmDr1KMeta } from "/vercel/path0/pages/dashboard/settings/organization/manage-tokens.vue?macro=true";
import { default as plans_45paymentsC8GjARf9vIMeta } from "/vercel/path0/pages/dashboard/settings/organization/plans-payments.vue?macro=true";
import { default as products_45servicesiKsISVCiVAMeta } from "/vercel/path0/pages/dashboard/settings/organization/products-services.vue?macro=true";
import { default as teamPjKdOf4fXIMeta } from "/vercel/path0/pages/dashboard/settings/organization/team.vue?macro=true";
import { default as settingsiT0n0ZleJjMeta } from "/vercel/path0/pages/dashboard/settings.vue?macro=true";
import { default as dashboardHdtnClvoGAMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as loadingUKTH8JpHMaMeta } from "/vercel/path0/pages/loading.vue?macro=true";
import { default as server_45errorZ7F1uzx4m1Meta } from "/vercel/path0/pages/server-error.vue?macro=true";
import { default as component_45stubJfjR9leuh3Meta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_g6dfs6okty7qcrlo3afs5qvlbm/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubJfjR9leuh3 } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_g6dfs6okty7qcrlo3afs5qvlbm/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "auth-booked",
    path: "/auth/booked",
    component: () => import("/vercel/path0/pages/auth/booked.vue").then(m => m.default || m)
  },
  {
    name: "auth-calendar",
    path: "/auth/calendar",
    component: () => import("/vercel/path0/pages/auth/calendar.vue").then(m => m.default || m)
  },
  {
    name: "auth-confirm",
    path: "/auth/confirm",
    component: () => import("/vercel/path0/pages/auth/confirm.vue").then(m => m.default || m)
  },
  {
    name: "auth-connect-email",
    path: "/auth/connect-email",
    component: () => import("/vercel/path0/pages/auth/connect-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: indexNwoJG7pLnGMeta || {},
    component: () => import("/vercel/path0/pages/auth/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-password-reset",
    path: "/auth/forgot-password/reset",
    meta: resetumQrL5DxQlMeta || {},
    component: () => import("/vercel/path0/pages/auth/forgot-password/reset.vue").then(m => m.default || m)
  },
  {
    name: "auth-onboarding",
    path: "/auth/onboarding",
    component: () => import("/vercel/path0/pages/auth/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "auth-signin",
    path: "/auth/signin",
    meta: signinBiAH3O7TaSMeta || {},
    component: () => import("/vercel/path0/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signupVML5S8aMRqMeta || {},
    component: () => import("/vercel/path0/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-email",
    path: "/auth/verify-email",
    component: () => import("/vercel/path0/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-activity-reports",
    path: "activity/reports",
    component: () => import("/vercel/path0/pages/dashboard/activity/reports.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-customer-contacts-id",
    path: "customer/contacts/:id()",
    component: () => import("/vercel/path0/pages/dashboard/customer/contacts/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-customer-contacts",
    path: "customer/contacts",
    component: () => import("/vercel/path0/pages/dashboard/customer/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-inbox",
    path: "inbox",
    component: () => import("/vercel/path0/pages/dashboard/inbox.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pipeline-leads-id",
    path: "pipeline/leads/:id()",
    component: () => import("/vercel/path0/pages/dashboard/pipeline/leads/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pipeline-leads",
    path: "pipeline/leads",
    component: () => import("/vercel/path0/pages/dashboard/pipeline/leads/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pipeline-opportunities",
    path: "pipeline/opportunities",
    component: () => import("/vercel/path0/pages/dashboard/pipeline/opportunities/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-resources-b2b-database-id",
    path: "resources/b2b-database/:id()",
    component: () => import("/vercel/path0/pages/dashboard/resources/b2b-database/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-resources-b2b-database",
    path: "resources/b2b-database",
    component: () => import("/vercel/path0/pages/dashboard/resources/b2b-database/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-resources-idb2b-resources-id",
    path: "resources/idb2b-resources/:id()",
    component: () => import("/vercel/path0/pages/dashboard/resources/idb2b-resources/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-resources-idb2b-resources-add",
    path: "resources/idb2b-resources/add",
    component: () => import("/vercel/path0/pages/dashboard/resources/idb2b-resources/add.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-resources-idb2b-resources",
    path: "resources/idb2b-resources",
    component: () => import("/vercel/path0/pages/dashboard/resources/idb2b-resources/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-resources-idb2b-resources-my-resources-id",
    path: "resources/idb2b-resources/my-resources/:id()",
    component: () => import("/vercel/path0/pages/dashboard/resources/idb2b-resources/my-resources/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-resources-idb2b-resources-my-resources-add",
    path: "resources/idb2b-resources/my-resources/add",
    component: () => import("/vercel/path0/pages/dashboard/resources/idb2b-resources/my-resources/add.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-resources-lookalike-search",
    path: "resources/lookalike-search",
    component: () => import("/vercel/path0/pages/dashboard/resources/lookalike-search/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-resources-lookalike-search-result",
    path: "resources/lookalike-search/result",
    component: () => import("/vercel/path0/pages/dashboard/resources/lookalike-search/result.vue").then(m => m.default || m)
  },
  {
    name: settingsiT0n0ZleJjMeta?.name,
    path: "settings",
    component: () => import("/vercel/path0/pages/dashboard/settings.vue").then(m => m.default || m),
    children: [
  {
    name: integrationrbPBxl8g9LMeta?.name,
    path: "connect/integration",
    component: () => import("/vercel/path0/pages/dashboard/settings/connect/integration.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-settings-connect-integration-name",
    path: ":name()",
    component: () => import("/vercel/path0/pages/dashboard/settings/connect/integration/[name].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-connect-integration-assembly-ai",
    path: "assembly-ai",
    component: () => import("/vercel/path0/pages/dashboard/settings/connect/integration/assembly-ai.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-connect-integration-better-contact",
    path: "better-contact",
    component: () => import("/vercel/path0/pages/dashboard/settings/connect/integration/better-contact.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-connect-integration",
    path: "",
    component: () => import("/vercel/path0/pages/dashboard/settings/connect/integration/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-connect-integration-smartlead-ai",
    path: "smartlead-ai",
    component: () => import("/vercel/path0/pages/dashboard/settings/connect/integration/smartlead-ai.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-settings-connect-twilio",
    path: "connect/twilio",
    component: () => import("/vercel/path0/pages/dashboard/settings/connect/twilio.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings",
    path: "",
    component: () => import("/vercel/path0/pages/dashboard/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-organization-general",
    path: "organization/general",
    component: () => import("/vercel/path0/pages/dashboard/settings/organization/general.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-organization-ideal-customer-profile",
    path: "organization/ideal-customer-profile",
    component: () => import("/vercel/path0/pages/dashboard/settings/organization/ideal-customer-profile.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-organization-lead-settings",
    path: "organization/lead-settings",
    component: () => import("/vercel/path0/pages/dashboard/settings/organization/lead-settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-organization-manage-tokens",
    path: "organization/manage-tokens",
    component: () => import("/vercel/path0/pages/dashboard/settings/organization/manage-tokens.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-organization-plans-payments",
    path: "organization/plans-payments",
    component: () => import("/vercel/path0/pages/dashboard/settings/organization/plans-payments.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-organization-products-services",
    path: "organization/products-services",
    component: () => import("/vercel/path0/pages/dashboard/settings/organization/products-services.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-organization-team",
    path: "organization/team",
    component: () => import("/vercel/path0/pages/dashboard/settings/organization/team.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "loading",
    path: "/loading",
    component: () => import("/vercel/path0/pages/loading.vue").then(m => m.default || m)
  },
  {
    name: "server-error",
    path: "/server-error",
    component: () => import("/vercel/path0/pages/server-error.vue").then(m => m.default || m)
  },
  {
    name: component_45stubJfjR9leuh3Meta?.name,
    path: "/",
    component: component_45stubJfjR9leuh3
  }
]